import Menu from "./Menu";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Clients from "./clients/clients";
import Events from "./events/events";
import Payments from "./payments/payments";
import Products from "./products/products";
import Users from "./users/users";
import { useContext, useEffect, useState } from "react";
import { AppUserContext } from "../App";
import Profile from "./profile/profile";
import Expenses from "./expenses/expenses";
import StaffPayments from "./staffpayments/staffpayments";
import Payroll from "./payroll/payroll";
import Requisitions from "./requisitions/requisitions";
import Accounts from "./accounts/accounts";
import Analytics from "./analytics/analytics";
import Vehicles from "./vehicles/vehicles";
import Schedules from "./scheduling/schedules";
import Drivers from "./drivers/drivers";
import Maintenance from "./maintenance/maintenance";
import { errorAlert, warningAlert } from "../components/toastr";
import { createSubscription, updateSubscription } from "../resources/api/pushsubscriptions";
import RequestPermission from "./requestPermission";
import Notifications, { NotificationClick } from "./notifications";
import Vendors from "./vendors/vendors";
import PurchaseOrders from "./purchase-orders/orders";
import Bills from "./bills/bills";
import BillPayments from "./bill-payments/payments";





/**
 * Displays Safaris Management App.
 */
const EventsApp = ({ logoutUser }) => {
    const { isLoggedIn } = useContext(AppUserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [permission_requested, setPermissionRequested] = useState(true);

    useEffect(() => {
        if (!isLoggedIn) {
            const path = encodeURIComponent(location.pathname);
            navigate(`/?redirect=${path}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, navigate])

    useEffect(() => {
        //1. Check for push mananger. 
        //2. Check if permission is not granted.
        //2b. Check if used has been prompted before. If so, and within a day we leave them
        //3. IF notification granted, then we get the subscription and update the database. 
        //4. 

        if (!isLoggedIn) return;

        if (!('PushManager' in window)) return;

        if (Notification.permission !== 'granted') {

            if (Notification.permission === "denied") {
                warningAlert("It looks like you rejected permissions for us to send you notifications. You can still change your mind in the settings.");
                return;
            }

            let last = parseInt(window.localStorage.getItem('lastPushPrompted')),
                timeinterval = 1000 * 60 * 60 * 24 * 1; //1 day in milliseconds

            if (!!last && ((Date.now() - last) < timeinterval)) return;

            return setPermissionRequested(false);
        }

        navigator.serviceWorker.getRegistration().then(reg => {
            if (!reg) return;

            reg.pushManager.getSubscription()
                .then(subscription => {
                    if (!subscription) {
                        setPermissionRequested(false);
                        return;
                    }

                    //update the subscription details

                    const data = subscription.toJSON();
                    const { endpoint } = data;
                    const key = data.keys.p256dh;
                    const token = data.keys.auth;
                    const id = localStorage.getItem('pushsubscriptionid');

                    let promise;

                    if (id) promise = updateSubscription({ endpoint, key, token }, id)
                    else promise = createSubscription({ endpoint, key, token });

                    promise
                        .then(({ subscription }) => {
                            window.localStorage.setItem('pushsubscriptionid', subscription.id);
                        })
                        .catch(errorAlert)
                })
        })


    }, [isLoggedIn])

    if (!permission_requested) return <RequestPermission setRequestPermission={setPermissionRequested} />



    return (
        <>
            <Menu logoutUser={logoutUser} />
            <Notifications />
            <div className="content-wrapper px-1 px-md-3">
                <Routes>
                    <Route path="notifications/:reference" element={<NotificationClick />} />
                    <Route path="me/*" element={<Profile />} />
                    <Route path="users/*" element={<Users />} />
                    <Route path="bill-payments/*" element={<BillPayments />} />
                    <Route path="bills/*" element={<Bills />} />
                    <Route path="purchase-orders/*" element={<PurchaseOrders />} />
                    <Route path="vendors/*" element={<Vendors />} />
                    <Route path="accounts/*" element={<Accounts />} />
                    <Route path="analytics/*" element={<Analytics />} />
                    <Route path="drivers/*" element={<Drivers />} />
                    <Route path="vehicles/*" element={<Vehicles />} />
                    <Route path="schedules/*" element={<Schedules />} />
                    <Route path="maintenance/*" element={<Maintenance />} />
                    <Route path="requisitions/*" element={<Requisitions />} />
                    <Route path="staff-payments/*" element={<StaffPayments />} />
                    <Route path="payroll/*" element={<Payroll />} />
                    <Route path="expenses/*" element={<Expenses />} />
                    <Route path="payments/*" element={<Payments />} />
                    <Route path="products" element={<Products />} />
                    <Route path="events/*" element={<Events />} />
                    <Route path="clients/*" element={<Clients />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="/" element={<Dashboard />} />
                </Routes>
            </div>
        </>
    )
}

export default EventsApp;