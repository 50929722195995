import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { CancelButton, SubmitButton } from "../../components/btns";
import { errorAlert, successAlert } from "../../components/toastr";
import { paymentMethods, } from "../../assets/constants";
import { UCWords } from "../../components/resources";
import { createRefuel, updateRefuel, updateRefuelExpense } from "../../resources/api/refuels";
import { DateSelector } from "../../components/datetimepicker";
import cur from "../../components/currency";
import { getAccounts } from "../../resources/api/accounts";


const { Control, Label } = Form;

/**
 * @param {Object} props
 * @param {"create"|"expense"|"refuel"} props.action
 * @param {(details: import("../../resources/api/refuels").RefuelObject) => void} props.onUpload
 * @param {{
 *  show: boolean
 *  setShow: React.Dispatch<React.SetStateAction<boolean>>
 * }} props.show
 * @param {{
 *  details: import("../../resources/api/refuels").RefuelObject,
 *  setDetails: React.Dispatch<React.SetStateAction<import("../../resources/api/refuels").RefuelObject>>
 * }} props.refuelDetails
 */
const RefuelForm = ({ onUpload, action = "create", refuelDetails, show: toShow, vehicle_id }) => {

    const inputref = useRef();

    const { show, setShow } = toShow;
    const [handlingSubmit, setHandlingSubmit] = useState(false);
    const [validated, setValidated] = useState(false);

    const { details, setDetails } = refuelDetails;
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {

        getAccounts()
            .then(({ accounts }) => {
                setAccounts(accounts.filter((a => a.isActive && (['fuel-card', 'cash'].indexOf(a.account_type) !== -1))));
            })
            .catch(errorAlert)
    }, [])



    /**
     * Handles the submission of the form
     * @param {React.MouseEvent} e 
     */
    const handleSubmit = e => {

        e.preventDefault();
        const form = inputref.current;

        if (!form.checkValidity()) {
            setValidated(true);
            errorAlert("Some errors have been detected in the form. These have been highlighted for you.", "Invalid Fields");
            return;
        }

        setHandlingSubmit(true);
        setValidated(false);

        let promise;

        if (action === "create") {
            promise = createRefuel({ ...details, vehicle_id });
        } else {
            promise = (action === "refuel") ? updateRefuel(details, details.id) : updateRefuelExpense(details, details.id);
        }

        promise
            .then(({ refuel, message }) => {
                form.reset();
                successAlert(message);
                onUpload(refuel);
                setShow(false);
            })
            .catch(e => errorAlert(e))
            .finally(() => setHandlingSubmit(false))
    }

    return (
        <Modal show={show} onExit={() => setValidated(false)} centered size="lg">
            <Modal.Body>
                <Form noValidate validated={validated} ref={inputref}>

                    {(action !== "expense") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Date</Label>
                                    <DateSelector
                                        value={details.refuel_date}
                                        onChange={date => setDetails(d => ({ ...d, refuel_date: date }))}
                                        placeholder="-"
                                        maxDate={0}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Date is required.
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Fuel Station</Label>
                                    <Control
                                        value={details.station}
                                        onChange={e => setDetails({ ...details, station: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Required
                                    </Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Odometer Reading</Label>
                                    <Control
                                        type="number"
                                        value={details.odometer_reading}
                                        onChange={e => setDetails({ ...details, odometer_reading: e.currentTarget.value })}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Required
                                    </Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col sm={5} className="my-1">
                                    <Label className="form-field-title">Total</Label>
                                    <Control
                                        type="number"
                                        value={details.total_cost}
                                        onChange={e => setDetails({ ...details, total_cost: e.currentTarget.value })}
                                        step={0.1}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Required
                                    </Control.Feedback>
                                </Col>
                                <Col sm={4} className="my-1">
                                    <Label className="form-field-title">Unit Cost</Label>
                                    <Control
                                        type="number"
                                        value={details.unit_cost}
                                        onChange={e => setDetails({ ...details, unit_cost: e.currentTarget.value })}
                                        step={0.01}
                                        required
                                    />
                                    <Control.Feedback type="invalid">
                                        Required
                                    </Control.Feedback>
                                </Col>
                                <Col sm={3} className="my-1">
                                    <Label className="form-field-title">No Litres</Label>
                                    <Control
                                        type="text"
                                        readOnly
                                        value={
                                            cur(
                                                parseFloat(details.total_cost || 0) / parseFloat(details.unit_cost || 1),
                                                2).format()
                                        }
                                    />
                                </Col>
                            </Row>
                        </>
                    }

                    {(action !== "refuel") &&
                        <>
                            <Row className="g-1">
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Payment Method</Label>
                                    <Form.Select
                                        as="select"
                                        value={details.method}
                                        onChange={e => setDetails({ ...details, method: e.currentTarget.value })}
                                        required
                                    >
                                        <option value=""></option>
                                        {paymentMethods.map(c => <option key={c} value={c}>{UCWords(c.replace(/-/g, " "))}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        You must select a method for payment used for this transaction.
                                    </Form.Control.Feedback>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Label className="form-field-title">Account</Label>
                                    <Form.Select
                                        as="select"
                                        value={details.account_id}
                                        onChange={e => setDetails({ ...details, account_id: e.currentTarget.value })}
                                        required
                                    >
                                        <option value=""></option>
                                        {accounts.map(c => <option key={c.id} value={c.id}>{c.title}</option>)}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        You must select the account this payment went into.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </>
                    }


                    <Row>
                        <Col className="mt-4 mb-3 text-end">
                            <SubmitButton isSubmitting={handlingSubmit} onClick={handleSubmit}>
                                {action === "edit" ? "Update" : "Submit"}
                            </SubmitButton>
                            <CancelButton isSubmitting={handlingSubmit} onClick={() => setShow(false)}>
                                Cancel
                            </CancelButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}


export default RefuelForm;