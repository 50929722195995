import { Fragment, useState } from "react";
import { Button, Tab, Table } from "react-bootstrap"
import { ErrorLoading, PageLoading } from "../../../components/loading";
import { useEffect } from "react";
import { getProductPayments, printAnalytics } from "../../../resources/api/dashboard";
import moment from "moment";
import cur from "../../../components/currency";
// import { VATEventCategories } from "../../../assets/constants";
import { APIURL } from "../../../resources/fetch";
import { errorAlert } from "../../../components/toastr";
import { UCWords } from "../../../components/resources";

const Payments = () => {


    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [payments, setPayments] = useState();

    const [years, setYears] = useState([]);
    const [totals, setTotals] = useState({});

    const [open, setOpen] = useState({});

    const handleOpen = (e, y, c = null) => {
        if (!c) {
            setOpen(opens => ({ ...opens, [y]: { ...opens[y], open: !opens[y].open } }))
        } else {
            setOpen(opens => ({ ...opens, [y]: { ...opens[y], [c]: !opens[y][c] } }))
        }
    }

    useEffect(() => {

        getProductPayments(true)
            .then(({ data: { products_payments } }) => {

                setPayments(products_payments);

                let _years = [], _data = {};

                products_payments.forEach(inc => {
                    let y = parseInt(inc.payment_year),
                        m = parseInt(inc.payment_month),
                        a = parseFloat(inc.amount),
                        p = inc.product,
                        // c = inc.category,
                        c_a = inc.classification;

                    if (_years.indexOf(y) === -1) _years.push(y);


                    if (!(y in _data)) _data[y] = {};

                    if (!(c_a in _data[y])) _data[y][c_a] = {};

                    if (!(p in _data[y][c_a])) _data[y][c_a][p] = {};

                    if (!(m in _data[y][c_a][p])) _data[y][c_a][p][m] = [];

                    _data[y][c_a][p][m].push(a);

                })


                setYears(_years.sort((a, b) => (b - a)));

                let _tt = 0, _t = 0;

                for (let y in _data) {
                    for (let c_a in _data[y]) {
                        for (let p in _data[y][c_a]) {
                            _data[y][c_a][p]['title'] = p;
                            for (let i = 1; i < 13; i++) {
                                if (!(i in _data[y][c_a][p])) {
                                    _data[y][c_a][p][i] = 0;
                                } else {
                                    _t = _data[y][c_a][p][i].reduce((p, c) => p + c, 0);

                                    _tt += _t;
                                    _data[y][c_a][p][i] = _t;
                                }
                            }
                            _data[y][c_a][p]['total'] = _tt;
                            _tt = 0;
                        }
                    }
                }

                let products, categories, _totals = {};
                _tt = 0; _t = 0;

                for (let y in _data) {

                    _totals[y] = {};

                    for (let c_a in _data[y]) {
                        products = Object.keys(_data[y][c_a]);


                        _totals[y][c_a] = {};

                        for (let i = 1; i < 13; i++) {
                            _t = products.map(p => _data[y][c_a][p][i]).reduce((p, c) => p + c, 0);
                            _totals[y][c_a][i] = _t;
                            _tt += _t;
                        }

                        _totals[y][c_a]['total'] = _tt;
                        _tt = 0;
                    }
                }

                let _open = {};

                for (let y in _totals) {

                    _open[y] = {
                        open: true
                    }

                    categories = Object.keys(_data[y]);

                    categories.forEach(c => _open[y][c] = false);


                    for (let i = 1; i < 13; i++) {
                        _t = categories.map(p => _totals[y][p][i]).reduce((p, c) => p + c, 0);
                        _totals[y][i] = _t;
                        _tt += _t;
                    }

                    _totals[y]['total'] = _tt;
                    _tt = 0;
                }

                setOpen(_open);
                setPayments(_data);
                setTotals(_totals);

            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [])


    const onDownloadClick = () => {

        const data = [];

        years.forEach(y => {

            data.push([
                y,
                Array(12).fill(0).map((i, e) => totals[y][e + 1]),
                totals[y]['total'],
                1
            ]);

            Object.keys(payments[y]).sort().forEach(c_a => {
                data.push([
                    c_a,
                    Array(12).fill(0).map((i, e) => totals[y][c_a][e + 1]),
                    totals[y][c_a]['total'],
                    2
                ]);

                Object.keys(payments[y][c_a]).sort().forEach((k, ee) => {
                    data.push([
                        `${ee + 1}. ${k}`,
                        Array(12).fill(0).map((i, e) => payments[y][c_a][k][e + 1]),
                        payments[y][c_a][k]['total'],
                        3
                    ]);
                });
            })

        })

        printAnalytics({
            title: "Products Payments",
            data,
            max_levels: 3
        })
            .then(() => {
                window.open(`${APIURL}/dashboard/analytics/pdf`);
            })
            .catch(errorAlert)

    }


    if (!loaded) return <PageLoading>Loading all product payments...</PageLoading>;

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Tab.Pane eventKey="payments">

            <Button variant="secondary" onClick={onDownloadClick}><i className="fas fa-print me-1" />Print</Button>

            <Table responsive size="sm" hover className="mt-3">
                <thead>
                    <tr>
                        <th>Year/Month</th>
                        {Array(12).fill(0).map((i, e) => <th key={e} className="text-center">{moment(e + 1, "M").format("MMM")}</th>)}
                        <th className="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {years.map(y => (
                        <Fragment key={y}>
                            <tr key={y} className="bg-secondary text-white">
                                <td>
                                    <h6 className="my-1">
                                        <a href="#." className="me-2 text-white" onClick={(e) => handleOpen(e, y)}>
                                            <i className="fas fa-plus" />
                                        </a>
                                        {y}
                                    </h6>
                                </td>
                                {Array(12).fill(0).map((i, e) => <td className="text-end fw-bold" key={e}>{totals[y][e + 1] === 0 ? "-" : cur(totals[y][e + 1], 0).format()}</td>)}
                                <td className="ps-4 fw-bold text-end">{cur(totals[y]['total'], 0).format()}</td>
                            </tr>

                            {open[y].open && Object.keys(payments[y]).sort().map(c_a => (
                                <Fragment key={`${y}_${c_a}`}>
                                    <tr className="fw-bold" style={{ backgroundColor: "rgba(0,0,0,.125)" }}>
                                        <td className="ps-4" style={{ whiteSpace: "nowrap" }}>
                                            <a href="#." className="me-2" onClick={(e) => handleOpen(e, y, c_a)}>
                                                <i className="fas fa-plus" />
                                            </a>
                                            {UCWords(c_a)}
                                        </td>
                                        {Array(12).fill(0).map((i, e) => <td className="text-end" key={e}>{totals[y][c_a][e + 1] === 0 ? "-" : cur(totals[y][c_a][e + 1], 0).format()}</td>)}
                                        <td className="text-end">{cur(totals[y][c_a]['total'], 0).format()}</td>
                                    </tr>
                                    {open[y][c_a] &&
                                        Object.keys(payments[y][c_a]).sort().map((k, ee) => (
                                            <tr key={`${y}_${c_a}_${k}`}>
                                                <td className="ps-5" style={{ whiteSpace: "nowrap" }}>{ee + 1}. {k}</td>
                                                {Array(12).fill(0).map((i, e) => <td className="text-end" key={e}>{payments[y][c_a][k][e + 1] === 0 ? "-" : cur(payments[y][c_a][k][e + 1], 0).format()}</td>)}
                                                <td className="ps-5 fw-bold text-end">{cur(payments[y][c_a][k]['total'], 0).format()}</td>
                                            </tr>
                                        ))
                                    }
                                </Fragment>
                            ))}

                        </Fragment>
                    ))}
                </tbody>

            </Table>
        </Tab.Pane>
    )
}

export { Payments as ProductPayments };