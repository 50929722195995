import Nav from '../../components/breadcrumb';
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { Table } from 'react-bootstrap';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import style from './expenses.scss';
import { useEffect, useState, useContext } from 'react';
import { ErrorLoading, PageLoading } from '../../components/loading';
import moment from "moment";
import cur from '../../components/currency';
import { nonChoiceExpenseCategories } from '../../assets/constants';
import { ConfirmAction } from '../../components/prompt';
import { errorAlert, infoAlert, successAlert } from '../../components/toastr';
import { AppUserContext } from '../../App';
// import { APIURL } from '../../resources/fetch';
import { getMaintenance, signoffMaintenance } from '../../resources/api/maintenance';
import { EditMaintenance } from './maintenance-manipulation';
import { ExecutePayment } from './processPayment';
import { UCWords } from '../../components/resources';


/**
 * handle for /app/expenses/:expenseid
 * @param {Object} props
 * @param {import('../../resources/api/maintenance').MaintenanceObject} props.details
 * 
 */
const ViewMaintenance = ({ details, setDetails }) => {

    const { profile } = useContext(AppUserContext);

    const nav_items = [
        { title: 'Maintenance', href: '/app/maintenance' },
        { title: details.reference }
    ];

    const style1 = { width: '150px', display: 'inline-block', fontWeight: 'normal' };

    const signOff = () => {

        infoAlert("Getting the activity signed off ...");

        signoffMaintenance(details.id)
            .then(({ maintenance, message }) => {
                successAlert(message);
                setDetails(d => ({ ...d, ...maintenance }));
            })
            .catch(e => errorAlert(e))
    }

    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="900">
                <Title>
                    {details.reference} {!!details.user_reference && <>({details.user_reference})</>}
                </Title>
                <ActionBar>

                    {(!!details.signoff_date && (!details.isPaid) && (profile.permission_level < 3)) &&
                        <ButtonToolbar>
                            <ExecutePayment
                                maintenance_id={details.id}
                                onUpload={maintenance => setDetails(d => ({ ...d, ...maintenance }))}
                            />
                        </ButtonToolbar>
                    }

                    {(!details.signoff_date && (profile.permission_level === 1)) &&
                        <ButtonToolbar>
                            <ConfirmAction
                                as={Btn}
                                title="Sign Off"
                                handleAction={signOff}
                                confirmationText='Are you sure you want to sign off this activity?'
                            >
                                <i className="fas fa-check-circle" />
                            </ConfirmAction>
                        </ButtonToolbar>
                    }

                    <ButtonToolbar>
                        {(!details.signoff_date) &&
                            <Btn href={`/app/maintenance/${details.id}/edit`} title="Edit Details">
                                <i className="fas fa-pencil-alt" />
                            </Btn>}
                        <Btn href="/app/requisitions/new" title="New Maintenance Activity">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-800 p-1 expenses">

                <div className="text-end">
                    TYPE: <span style={style1}>{details.type.toUpperCase()}</span> <br />
                    VEHICLE: <span style={style1}>{details.vehicle.reg_no}</span> <br />
                    REFERENCE: <span style={style1}>{details.reference}</span> <br />
                    ACTIVITY DATE: <span style={style1}>{moment(details.maintenance_date).format("DD MMMM YYYY")}</span> <br />
                </div>

                <div className="my-3">
                    <span style={{ height: '100px' }} className="px-3 px-sm-4 d-inline-flex align-items-center justify-content-end bg-teal text-white font-weight-bold rounded">
                        UGX <span style={{ fontSize: '2rem' }} className="d-inline-block ms-2">{cur(details.total_cost, 0).format()}</span>
                    </span>
                </div>
                
                <Table size="sm" borderless style={{ maxWidth: '600px' }}>
                    <colgroup>
                        <col span="1" style={{ width: "40%" }} />
                        <col span="1" style={{ width: "60%" }} />
                    </colgroup>

                    <tbody>
                        <tr>
                            <td>Odometer Reading</td>
                            <td>{cur(details.odometer_reading, 0).format()}</td>
                        </tr>
                        <tr>
                            <td>Garage</td>
                            <td>{details.garage} </td>
                        </tr>
                        <tr>
                            <td>Signed Off</td>
                            <td>
                                {!details.signoff_date ?
                                    "Not Approved" :
                                    <span>By {details.signed_off_contact.display_name} on {moment(details.signoff_date).format("DD MMMM YYYY")}</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>Paid</td>
                            <td>
                                {!details.isPaid ?
                                    "Not Paid" :
                                    <span>
                                        Paid on {moment(details.expense.expense).format("DD MMMM YYYY")}
                                        <Link className='d-block' to={`/app/expenses/${details.expense_id}`}>View Expense</Link>
                                    </span>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>


                <div className="mt-3">
                    <h6>Observations</h6>
                    <p>{details.observations}</p>
                </div>
                <div className="mt-3">
                    <h6>Action</h6>
                    <p>{details.actions}</p>
                </div>
                <div className="mt-3">
                    <h6>Remarks</h6>
                    <p>{details.remarks || 'n/a'}</p>
                </div>



                {(details.items.length > 0) && <MaintenanceItems items={details.items} />}

                {(nonChoiceExpenseCategories.indexOf(details.category) !== -1) &&
                    <p className='lead text-info fw-bold'>To view the breakdown go to payroll.</p>}



                {/* <div className="mt-5 mb-2">
                    <h5>Notes</h5>
                    <p>{faker.lorem.sentences(5)}</p>
                </div> */}

            </div>
        </>
    )
}


/**
 * 
 * @param {Object} props
 * @param {import('../../resources/api/maintenance').MaintenanceItemObject[]} props.items 
 * @returns 
 */
const MaintenanceItems = ({ items }) => (
    <div className={`mt-3 mt-sm-4 mb-2 ${style}`}>
        <h5>Items</h5>

        <Table responsive style={{ minWidth: '600px' }} hover className="items-table">
            {/* <colgroup>
                <col span="1" style={{ width: "3%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "45%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "10%" }} />
                <col span="1" style={{ width: "22%" }} />
            </colgroup> */}
            <thead>
                <tr>
                    <th>#</th>
                    <th>Part</th>
                    <th className='text-start'>Notes</th>
                    <th>Action</th>
                    <th className='text-end'>Amount</th>
                </tr>
            </thead>
            <tbody>
                {items.map((i, idx) => (
                    <tr key={i.id}>
                        <td>{idx + 1}</td>
                        <td className='text-start'>{i.part}</td>
                        <td className='text-start' style={{ fontSize: "95%", lineHeight: "1.1" }}>{i.notes}</td>
                        <td>{UCWords(i.action)}</td>
                        <td className='text-end'>{cur(i.amount, 0).format()}</td>
                    </tr>
                ))}
            </tbody>

        </Table>

    </div>
)

const MaintenanceDetails = () => {

    const { maintenanceid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {

        setError(null);
        setLoaded(false);

        getMaintenance(maintenanceid, ['items', 'expense', 'signed_off_contact', 'vehicle'])
            .then(({ maintenance }) => {
                setDetails(maintenance);
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true))

    }, [maintenanceid])

    //load events details

    if (!isLoaded) {
        return <PageLoading>Loading activity details...</PageLoading>
    }

    if (error) {
        return <ErrorLoading>{error}</ErrorLoading>
    }

    return (
        <Routes>
            <Route path="/edit" element={<EditMaintenance details={details} setDetails={setDetails} />} />
            <Route path="/" element={<ViewMaintenance details={details} setDetails={setDetails} />} />
        </Routes>
    )

}

export default MaintenanceDetails;